@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&family=Rubik:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *:after, *:before {
  box-sizing: border-box;
}

html {

  --font: 'Rubik', sans-serif;

  --font-title: 'Rubik', sans-serif;

  --font-size: 1.2rem;

  --color: #a69f64;

  --color-light: #bbbc8f;

  --color-dark:#ffffff;

  --radius: 0.2rem;
}

body {
  margin: 0;
}

body, input, textarea, button {
  font-family: 'Rubik', sans-serif;
  font-family: var(--font);
  font-size: 1.2rem;
  font-size: var(--font-size);
}

h1, h2, h3 {
  font-family: 'Rubik', sans-serif;
  font-family: var(--font);
}

h1, h2, h3, p, ul {
  margin: 1rem 0;
}

ul {
  list-style: square;
}

li {
  margin-bottom: 0.5rem;
  list-style: none;
}

label {
  display: block;
  margin-bottom: 0.25rem;
}

input:not( [ type = 'checkbox' ] ), textarea, select {
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  border-radius: var(--radius);
  display: block;
  padding: 0.8rem;
  transition: 0.2s;
}

input:focus, textarea:focus {
  background: white;
  border-color: #a69f64;
  border-color: var(--color);
  box-shadow: 0 0 0 3px #bbbc8f;
  box-shadow: 0 0 0 3px var(--color-light);
  outline: none;
}

button {
  background: #a69f64;
  background: var(--color);
  border: none;
  border-radius: 0.2rem;
  border-radius: var(--radius);
  color: #ffffff;
  color: var(--color-dark);
  cursor: pointer;
  padding: 0.8rem 1.6rem;
  transition: 0.1s;
}

button:hover, button:focus {
  background: #a69f64;
  background: var(--color);
  box-shadow: 0 0 0 3px #bbbc8f , 0 0 0 4px #a69f64;
  box-shadow: 0 0 0 3px var(--color-light) , 0 0 0 4px var(--color);
  outline: none;
}

button:active {
  box-shadow: 0 0 0 3px #a69f64 , 0 0 0 4px #a69f64;
  box-shadow: 0 0 0 3px var(--color) , 0 0 0 4px var(--color);
}

button:disabled {
  cursor: wait;
  opacity: 0.5;
}

hr {
  border: 2px solid black;
  margin: 2rem auto;
}

a {
  text-decoration: none;
  color: #7c7772;
  font-weight: 700;
}

header {
  text-align: center;
  background-color: #f5f5e8;
}

header img {
  width: 400px;
  margin: 20px 0;
}

footer {
  text-align: center;
  background-color: #a69f64;
  margin: 0;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

footer h4{
  margin: 0;
  margin-top: 13px;
  font-weight: 600;
  font-size: 1.1rem;
}

footer p{
  margin: 0;
  margin-bottom: 13px;
  font-weight: 400;
  font-size: 1.1rem;
}

footer a {
  color: white;
  font-weight: 400;
}

footer a:hover {
  color: white;
  font-weight: 600;
}

.searchBar {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  align-items: center;
}

.searchInto {
  flex: 1 1;
  display: flex;
  justify-content: center;
  margin-right: -120px;
}

.searchInto input {
  width: 400px;
  margin-right: 10px;
}

.linkCart {
  margin-right: 60px;
}

.divLinkCart {
  display: flex;
  align-items: center;
}

.divLinkCart span {
  margin: 0 10px;
}

.divLinkCart img {
  width: 35px;
}

.mainContent {
  display: flex;
  background-color: rgb(245, 242, 242);
  padding-top: 50px;
  padding-bottom: 50px;
}

.productsFilter {
 margin-left: 25px;
 flex: 0.9 1;
}

.serachParagraph {
  flex: 3.7 1;
  margin: 0 50px;
  text-align: center;
}

.noProductParagraph {
  flex: 3.7 1;
  margin: 0 50px;
  text-align: center;
}

.productsBoard {
  flex: 3.7 1;
  margin: 0 50px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.categories-list {
  margin: 20px 0;
  padding: 30px;
  border: solid #bbbc8f98;
  border-radius: 20px;
  font-size: 1rem;
  background-color: #bbbc8f98;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.categoryItem {
  margin: 15px 0;
}

.categoryItem label {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.categoryItem input {
  margin-right: 20px;
}

.productCart {
  width: 250px;
  height: 500px;
  background-color: rgb(255, 255, 255);
  border: solid #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 30px;
  margin: 20px 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.productCart h3 {
  font-size: 1.0rem;
  height: 60px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.productCart img {
  width: 180px;
  height: 180px;
  border: solid #bbbc8f;
  border-radius: 20px;
  margin: 20px 0;
}

.productCart button {
  padding: 0.4rem 0.8rem;
  margin-bottom: 10px;
}

.productCart p {
  margin: 0;
}

.productDetailPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linkCart2 {
  align-self: flex-end;
  margin: 30px 0;
  margin-right: 60px;
}

.detailMain{
  background-color: rgb(245, 242, 242);
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
}

.detailPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detailProduct {
  width: 100%;
  height: 550px;
  background-color: rgb(255, 255, 255);
  border: solid #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  margin: 20px 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.detailProduct h4 {
  font-size: 1.2rem;
  margin: 0;
  margin-top: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detailProduct h3 {
  font-size: 1.3rem;
  margin: 0;
}

.detailProduct img {
  width: 250px;
  height: 250px;
  border: solid #bbbc8f;
  border-radius: 20px;
}

.detailProduct a {
  margin-bottom: 20px;
}

.detailForm {
  width: 100%;
  background-color:#d7d7b8;
  border: solid #d7d7b8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  margin: 20px 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
}

.detailForm #user-email {
  width: 100%;
  margin-bottom: 25px;
}

.ratingRadio {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 15px 0;
}

.ratingRadio span {
  margin-left: -15px;
  font-size: 1rem;
}

.detailForm #detail-evaluation {
  width: 100%;
  margin-bottom: 25px;
}

.detailForm button {
  align-self: flex-end;
  margin: 15px 0;
}

.customerGrade h3 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-left: 20px;
}

.customerGrade h2 {
  color: #7c7772;
  font-size: 1.2rem;
  font-weight: 400;
}

.customerGrade span {
  color: #7c7772;
  font-size: 1.1rem;
  font-weight: 400;
}

.customerGrade p {
  color: #7c7772;
  font-size: 1.1rem;
  font-weight: 400;
}

.evaluation-card {
  width: 500px;
  background-color: rgb(255, 255, 255);
  border: solid #ffffff;
  padding: 10px 30px;
  margin: 20px 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin-bottom: 30px;
}

.cartPage {
  background-color: rgb(245, 242, 242);
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.mainCart {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  margin-bottom: 50px;
}

.mainCart h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 50px;
}

.cartProductSession {
  width: 80%;
  background-color: rgb(255, 255, 255);
  border: solid #ffffff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  margin: 20px 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.cartProductSession img {
  width: 165px;
  height: 165px;
  border: solid #bbbc8f;
  border-radius: 20px;
}

.cartProductSession h1 {
  font-size: 1.1rem;
  font-weight: 500;
  color: #7c7772;
  width: 600px;
  margin: 0 20px;
}

.quantitySession {
  display: flex;
  margin: 0 15px;
}

.quantitySession button {
  padding: 0.3rem 0.3rem 1.1rem 0.3rem;
  height: 30px;
  align-self: center;
  margin: 0 10px;
}

.priceCart {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  width: 300px;
}

.priceCart p {
  margin: 5px 0;
  font-size: 1.0rem;
  font-weight: 600;
  color: #7c7772;
}

.cartButtons {
  margin-bottom: 25px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  width: 63%;
}

.cartButtons button {
  margin-left: 20px;
}

.checkoutPage {
  background-color: rgb(245, 242, 242);
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.checkoutProduct {
  width: 65%;
  background-color: rgb(255, 255, 255);
  border: solid #ffffff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  margin: 20px 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.checkoutProduct img {
  width: 165px;
  height: 165px;
  border: solid #bbbc8f;
  border-radius: 20px;
}

.titleProductCheckout {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titleProductCheckout h1 {
  margin: 8px 0;
  font-size: 1.2rem;
  font-weight: 500;
  color: #a69f64;
}

.titleProductCheckout p {
  margin: 8px 0;
}

.priceProductCheckout {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.priceProductCheckout p {
  margin: 8px 0;
  color: #7c7772;
  font-size: 1.2rem;
  font-weight: 600;
}

.priceTotalCheckout {
  font-weight: 700;
  color: #968f51;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border: solid #ffffff;
  padding: 15px 30px;
  margin: 50px 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.formCheckout {
  width: 65%;
  background-color:#d7d7b8;
  border: solid #d7d7b8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 50px;
  margin: 20px 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin-bottom: 60px;
}

.formCheckout h3 {
  margin: 0;
  margin-bottom: 30px;
  margin-left: 13px;
}

.nameForm {
  width: 100%;
}

.nameForm label {
  text-align: start;
  margin: 15px 10px
}

.nameForm input {
  width: 100%;
}

.statsForm {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.statsForm label {
  text-align: start;
  width: 100%;
  margin: 15px 10px
}

.statsForm input {
  width: 100%;
}

.adressForm {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.adressForm label {
  text-align: start;
  width: 100%;
  margin: 15px 10px
}

.adressForm input {
  width: 100%;
}

.adressForm #checkout-address {
  flex: 2.2 1;
}

.adressForm #checkout-cep {
  flex: 0.8 1;
}

.buttonsCheckout {
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-end;
  width: 63%;
}

.buttonsCheckout button {
  margin-left: 20px;
}

